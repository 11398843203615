import { CurrencyUnitAbbreviation } from '@constants/enums';

export function arrayRowsPerPage({
  maxItemPerPage = 100,
  itemsCount,
}: {
  maxItemPerPage: number;
  itemsCount: number;
}) {
  const arr: number[] = [maxItemPerPage];
  for (let i = maxItemPerPage * 2; i < itemsCount; i += 10) {
    arr.push(i);
  }
  return arr;
}

export function formatCurrencyWithoutFractionDigits(value: number) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    notation: 'standard',
    maximumFractionDigits: 0,
  });

  return formatter.format(value);
}

const includeCurrencyUnitAbbreviation = (value: string) => {
  return (
    value.includes(CurrencyUnitAbbreviation.K) ||
    value.includes(CurrencyUnitAbbreviation.M) ||
    value.includes(CurrencyUnitAbbreviation.B) ||
    value.includes(CurrencyUnitAbbreviation.T)
  );
};

export function currencyFormatter(value: number) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    notation: 'compact',
    compactDisplay: 'short',
    maximumFractionDigits: 2,
  });

  let formattedValue = formatter.format(value);

  // Remove '.00' if it exists
  formattedValue = formattedValue.replace(/\.00$/, '');

  const currencySymbol = formattedValue.slice(-1);

  // If the value is in thousands (K) or millions (M), truncate it manually
  if (includeCurrencyUnitAbbreviation(formattedValue)) {
    const parts = formattedValue.split('');
    const decimalIndex = parts.indexOf('.');
    if (decimalIndex !== -1) {
      // Keep only two digits after decimal
      parts.splice(decimalIndex + 3);
      // Remove '.00' if it exists
      if (parts[decimalIndex + 1] === '0' && parts[decimalIndex + 2] === '0') {
        parts.splice(decimalIndex, 3);
      }
    }
    formattedValue = parts.join('');
  } else {
    // For non-compact numbers, remove '.00' if it exists
    formattedValue = formattedValue.replace(/\.00$/, '');
  }

  // Remove unnecessary trailing zeroes in the fractional part
  formattedValue = formattedValue.replace(/(\.\d*?)0+(\D|$)/, '$1$2');

  switch (true) {
    case value < 1000:
      return formattedValue;
    case !formattedValue.includes('NaN') && formattedValue !== '$0':
      return includeCurrencyUnitAbbreviation(formattedValue)
        ? formattedValue
        : formattedValue + currencySymbol;
    case formattedValue.includes('NaN') || formattedValue === '$0':
      return '$0';
    default:
      return '$0';
  }
}

export function filterByFormulaEncode(formula: string) {
  return encodeURIComponent(formula).replace(/%20/g, '+');
}

export function whatsappPhoneFormatter(phone: string) {
  return phone.replace(/[^0-9]/g, '');
}

export function urlFormatter(url: string) {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `http://${url.trim()}`;
  }
  return url.trim();
}

export const openSocialMediaMap = (key: string) => {
  switch (key) {
    case 'linkedin':
      return 'LinkedIn';
    case 'email':
      return 'Email';
    case 'whatsapp':
      return 'WhatsApp';
    case 'discord':
      return 'Discord';
    default:
      return '';
  }
};

export const convertEnumToArray = <T extends { [key: string]: string | number }>(
  enumObj: T,
): Array<T[keyof T]> => {
  return Object.values(enumObj) as Array<T[keyof T]>;
};

export const convertEnumToObject = (enumInput: any) => {
  return { ...enumInput };
};

export const isTestName = (name: string) => {
  return name.toLowerCase().includes('test');
};
export const isXAIgnite = (name: string) => {
  return name.toLowerCase().includes('xa ignite');
};
